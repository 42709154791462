export const getDateStr = (date = undefined) => {
  const currentdate = date ? new Date(date) : new Date();
  return `${currentdate.getUTCDate()}-${
    currentdate.getUTCMonth() + 1
  }-${currentdate.getUTCFullYear()} ${currentdate.getUTCHours()}-${currentdate.getUTCMinutes()}`;
};

export const formatDateTime = (date = undefined) => {
  const currentdate = date ? new Date(date) : new Date();
  const padZero = (num) => (num < 10 ? `0${num}` : num);
  return `${padZero(currentdate.getUTCDate())}/${
    padZero(currentdate.getUTCMonth() + 1)
  }/${currentdate.getUTCFullYear()} ${padZero(currentdate.getUTCHours())}:${padZero(currentdate.getUTCMinutes())}`;
};
